import { RuleId } from 'modules/automation-rules/types/automation-rule-interface'
import httpClient from 'shared/utils/http-client'

export const AUTOMATION_RULES_API = '/api/dashboard/customer/automation/rules'
export const AUTOMATION_RULES_FILTER_API = '/api/dashboard/customer/automation/rules/filter'

export const onChangeRuleStatus = (isActive: boolean, ruleId: RuleId) =>
  httpClient.post(`${AUTOMATION_RULES_API}/${ruleId}/${isActive ? 'deactivate' : 'activate'}`)

export const onDeleteRule = (ruleId: RuleId) =>
  httpClient.delete(`${AUTOMATION_RULES_API}/${ruleId}`)
