import React from 'react'

function OpenInNewIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7517 12.6416H1.46897V2.35886H6.61035V0.889893H1.46897C0.65369 0.889893 0 1.55093 0 2.35886V12.6416C0 13.4496 0.65369 14.1106 1.46897 14.1106H11.7517C12.5597 14.1106 13.2207 13.4496 13.2207 12.6416V7.50025H11.7517V12.6416ZM8.07932 0.889893V2.35886H10.7161L3.49615 9.57883L4.53177 10.6145L11.7517 3.39448V6.03128H13.2207V0.889903L8.07932 0.889893Z"
      />
    </svg>
  )
}

export default OpenInNewIcon
