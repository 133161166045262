import { BlogEntityEnum, BlogPageEnum } from 'modules/automation-rules/enums/automation-rule-enum'
import {
  BlogEntityDataInterface,
  FilterBaseApiInterface,
  FilterBlogApiInterface,
} from 'modules/automation-rules/types/automation-rule-filter-interface'
import { AutomationRuleBaseDataInterface } from 'modules/automation-rules/types/automation-rule-interface'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'

export const getData = (
  apiData?: FilterBaseApiInterface[],
): AutomationRuleBaseDataInterface[] | undefined =>
  apiData?.map(el => ({ id: el.id, caption: el.name }))

export const getPipelineStageData = (
  apiData?: DataWithPaginationInterface<{
    id: number
    name: string
    stages: { id: number; name: string }[]
  }>,
): AutomationRuleBaseDataInterface[] | undefined => {
  if (!apiData) return
  const pipeline = apiData.items[0]
  return !!pipeline ? pipeline.stages.map(el => ({ id: el.id, caption: el.name })) : []
}

export const getDataFromObj = ({
  id,
  name,
}: {
  id?: number
  name?: string
}): AutomationRuleBaseDataInterface[] => [{ id: id || 0, caption: name || '' }]

export const getBlogEntityData = (
  apiData?: FilterBlogApiInterface[],
): BlogEntityDataInterface | undefined =>
  apiData?.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: {
        [BlogEntityEnum.blog_post_layout]: cur.blog_post_layout,
        [BlogEntityEnum.blog_layout]: cur.blog_layout,
      },
    }
  }, {} as BlogEntityDataInterface)

export const getBlogEntityId = (
  entityData: BlogEntityDataInterface,
  blogId: number,
  entity: BlogEntityEnum | BlogPageEnum,
) => {
  if (entity === BlogEntityEnum.blog_layout || entity === BlogEntityEnum.blog_post_layout) {
    return entityData[blogId][entity]
  } else {
    return undefined
  }
}

export const getBlogEntityValue = (entity?: BlogEntityEnum | BlogPageEnum) => {
  return entity === BlogPageEnum.blog_static || entity === BlogPageEnum.blog_home
    ? BlogEntityEnum.blog_page
    : BlogEntityEnum[entity as keyof typeof BlogEntityEnum]
}
