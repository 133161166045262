import { LocaleEnum, localeLanguages } from 'shared/enums/locale-enum'
import { TLocoType } from 'shared/hooks/use-loco-translation'

export const getLocaleSelectOptions = (t: TLocoType): { id: LocaleEnum; caption: string }[] =>
  Object.values(LocaleEnum).map(locale => ({
    id: locale,
    caption: t(localeLanguages[locale]),
  }))

// export const getFullySupportedLocaleSelectOptions = (
//   t: TLocoType,
// ): { id: FullySupportedLocaleEnum | undefined; caption: string }[] =>
//   Object.values(FullySupportedLocaleEnum).map(locale => ({
//     id: locale,
//     caption: t(fullySupportedLocaleLanguages[locale]),
//   }))
