import { useCallback, useRef } from 'react'
import { useSwrKeyWithVersion } from 'shared/hooks/use-controlled-swr/use-swr-key-with-version'
import useSWR, { BareFetcher, Key, SWRConfiguration } from 'swr'

export const useControlledSwr = <Data = any, Error = any>(
  key: Key,
  fetcher: BareFetcher<Data>,
  config?: SWRConfiguration<Data, Error, BareFetcher<Data>>,
  isFetchAllowed: boolean = true,
) => {
  const { keyWithVersion, updateVersion } = useSwrKeyWithVersion(key)

  const aborter = useRef<AbortController>()
  const abort = useCallback(() => aborter.current?.abort(), [])

  const swrData = useSWR<Data>(
    isFetchAllowed ? keyWithVersion : undefined,
    ([url]) => {
      aborter.current = new AbortController()
      return fetcher?.(url, { signal: aborter.current.signal })
    },
    config,
  )

  return { ...swrData, abort, updateVersion }
}
