import { AutomationRuleTriggersEnum } from 'modules/automation-rules/triggers/enums/trigger-enum'

export const triggerActionLocoKeys: Record<AutomationRuleTriggersEnum, string> = {
  [AutomationRuleTriggersEnum.tag_added]: 'dashboard.automation_rule.trigger.tag_added',
  [AutomationRuleTriggersEnum.tag_removed]: 'dashboard.automation_rule.trigger.tag_removed',
  [AutomationRuleTriggersEnum.form_subscribed]: 'dashboard.automation_rule.trigger.form_subscribed',
  [AutomationRuleTriggersEnum.blog_page_form_subscribed]:
    'dashboard.automation_rule.trigger.blog_page_form_subscribed',
  [AutomationRuleTriggersEnum.campaign_completed]:
    'dashboard.automation_rule.trigger.campaign_completed',
  [AutomationRuleTriggersEnum.registered_to_webinar]:
    'dashboard.automation_rule.trigger.registered_to_webinar',
  [AutomationRuleTriggersEnum.enrolled_in_course]:
    'dashboard.automation_rule.trigger.enrolled_in_course',
  [AutomationRuleTriggersEnum.enrolled_in_course_bundle]:
    'dashboard.automation_rule.trigger.enrolled_in_course_bundle',
  [AutomationRuleTriggersEnum.new_sale]: 'dashboard.automation_rule.trigger.new_sale',
  [AutomationRuleTriggersEnum.email_link_clicked]:
    'dashboard.automation_rule.trigger.email_link_clicked',
  [AutomationRuleTriggersEnum.page_visited]: 'dashboard.automation_rule.trigger.page_visited',
  [AutomationRuleTriggersEnum.sale_canceled]: 'dashboard.automation_rule.trigger.sale_canceled',
  [AutomationRuleTriggersEnum.enrolled_in_community]:
    'dashboard.automation_rule.trigger.enrolled_in_community',
  [AutomationRuleTriggersEnum.course_completed]:
    'dashboard.automation_rule.trigger.course_completed',
  [AutomationRuleTriggersEnum.module_completed]:
    'dashboard.automation_rule.trigger.module_completed',
  [AutomationRuleTriggersEnum.lecture_completed]:
    'dashboard.automation_rule.trigger.lecture_completed',
  [AutomationRuleTriggersEnum.email_opened]: 'dashboard.automation_rule.trigger.email_opened',
  [AutomationRuleTriggersEnum.subscription_payment_failed]: 'dashboard.automation_rule.trigger.subscription_payment_failed'
}

export const triggerDescriptionLocoKeys: Record<AutomationRuleTriggersEnum, string> = {
  [AutomationRuleTriggersEnum.tag_added]: 'dashboard.automation_rule.trigger.description.tag_added',
  [AutomationRuleTriggersEnum.tag_removed]:
    'dashboard.automation_rule.trigger.description.tag_removed',
  [AutomationRuleTriggersEnum.form_subscribed]:
    'dashboard.automation_rule.trigger.description.form_subscribed',
  [AutomationRuleTriggersEnum.blog_page_form_subscribed]:
    'dashboard.automation_rule.trigger.description.blog_page_form_subscribed',
  [AutomationRuleTriggersEnum.campaign_completed]:
    'dashboard.automation_rule.trigger.description.campaign_completed',
  [AutomationRuleTriggersEnum.registered_to_webinar]:
    'dashboard.automation_rule.trigger.description.registered_to_webinar',
  [AutomationRuleTriggersEnum.enrolled_in_course]:
    'dashboard.automation_rule.trigger.description.enrolled_in_course',
  [AutomationRuleTriggersEnum.enrolled_in_course_bundle]:
    'dashboard.automation_rule.trigger.description.enrolled_in_course_bundle',
  [AutomationRuleTriggersEnum.new_sale]: 'dashboard.automation_rule.trigger.description.new_sale',
  [AutomationRuleTriggersEnum.email_link_clicked]:
    'dashboard.automation_rule.trigger.description.email_link_clicked',
  [AutomationRuleTriggersEnum.page_visited]:
    'dashboard.automation_rule.trigger.description.page_visited',
  [AutomationRuleTriggersEnum.sale_canceled]:
    'dashboard.automation_rule.trigger.description.sale_canceled',
  [AutomationRuleTriggersEnum.enrolled_in_community]:
    'dashboard.automation_rule.trigger.description.enrolled_in_community',
  [AutomationRuleTriggersEnum.course_completed]:
    'dashboard.automation_rule.trigger.description.course_completed',
  [AutomationRuleTriggersEnum.module_completed]:
    'dashboard.automation_rule.trigger.description.module_completed',
  [AutomationRuleTriggersEnum.lecture_completed]:
    'dashboard.automation_rule.trigger.description.lecture_completed',
  [AutomationRuleTriggersEnum.email_opened]:
    'dashboard.automation_rule.trigger.description.email_opened',
  [AutomationRuleTriggersEnum.subscription_payment_failed]: 'dashboard.automation_rule.trigger.description.subscription_payment_failed'
}
