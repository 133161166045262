export enum AutomationRuleTriggersDataEnum {
  tag = 'tag',
  form_subscribed = 'form_subscribed',
  form_subscribed_funnel_step = 'form_subscribed_funnel_step',
  blog_page_form_subscribed = 'blog_page_form_subscribed',
  blog_page_form_subscribed_page = 'blog_page_form_subscribed_page',
  blog_page_form_subscribed_post = 'blog_page_form_subscribed_post',
  campaign_completed = 'campaign_completed',
  registered_to_webinar = 'registered_to_webinar',
  registered_to_webinar_funnel_step = 'registered_to_webinar_funnel_step',
  course = 'course',
  module = 'module',
  lecture = 'lecture',
  enrolled_in_course_bundle = 'enrolled_in_course_bundle',
  enrolled_in_community = 'enrolled_in_community',
  new_sale = 'new_sale',
  new_sale_funnel_step = 'new_sale_funnel_step',
  sale_canceled = 'sale_canceled',
  sale_canceled_funnel_step = 'sale_canceled_funnel_step',
  email = 'email',
  email_link = 'email_link',
  page_visited = 'page_visited',
  page_visited_funnel_step = 'page_visited_funnel_step',
  subscription_payment_failed = 'subscription_payment_failed',
  subscription_payment_failed_funnel_step = 'subscription_payment_failed_funnel_step',
}

export enum AutomationRuleTriggersEnum {
  tag_added = 'tag_added',
  tag_removed = 'tag_removed',
  form_subscribed = 'form_subscribed',
  blog_page_form_subscribed = 'blog_page_form_subscribed',
  campaign_completed = 'campaign_completed',
  registered_to_webinar = 'registered_to_webinar',
  enrolled_in_course = 'enrolled_in_course',
  course_completed = 'course_completed',
  module_completed = 'module_completed',
  lecture_completed = 'lecture_completed',
  enrolled_in_course_bundle = 'enrolled_in_course_bundle',
  enrolled_in_community = 'enrolled_in_community',
  new_sale = 'new_sale',
  sale_canceled = 'sale_canceled',
  email_opened = 'email_opened',
  email_link_clicked = 'email_link_clicked',
  page_visited = 'page_visited',
  subscription_payment_failed = 'subscription_payment_failed',
}
