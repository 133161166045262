import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useControlledSwr } from 'shared/hooks/use-controlled-swr'
import useUser from 'shared/hooks/use-user'
import { CRM_PIPELINE_API } from '../api/crm-pipeline-api'
import { CrmPipelineApiInterface } from '../types/pipeline-interface'

export function useCrmPipelineList(shouldFetch: boolean = true) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CrmPipelineApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: crmPipelineList, mutate } = useControlledSwr(
    () => CRM_PIPELINE_API,
    fetcher,
    {},
    shouldFetch && !!user,
  )

  return { crmPipelineList, mutate }
}
