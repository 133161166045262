import { TLocoType } from 'shared/hooks/use-loco-translation'

export enum AccessTypeEnum {
  fullAccess = 'full_access',
  partialAccess = 'partial_access',
  drippingContent = 'dripping_content',
}

export const AccessTypeCaptions = {
  [AccessTypeEnum.fullAccess]: 'dashboard.course.access_type.full_access',
  [AccessTypeEnum.partialAccess]: 'dashboard.course.access_type.partial_access',
  [AccessTypeEnum.drippingContent]: 'dashboard.course.access_type.drip_content',
}

export function getCourseAccessTypeDataOptions(t: TLocoType) {
  return Object.values(AccessTypeEnum).map(accessType => {
    return {
      id: accessType,
      caption: t(AccessTypeCaptions[accessType]),
    }
  })
}
