export enum BlogPageEnum {
  blog_layout = 'blog_layout',
  blog_post_layout = 'blog_post_layout',
  blog_home = 'blog_home',
  blog_static = 'blog_static',
  blog_post_body = 'blog_post_body',
}

export enum BlogEntityEnum {
  blog_layout = 'blog_layout',
  blog_post_layout = 'blog_post_layout',
  blog_page = 'blog_page',
  blog_post_body = 'blog_post_body',
}

export enum BlogEntityTitleEnum {
  blog_layout = 'dashboard.automation_rule.blog_layout',
  blog_post_body = 'dashboard.automation_rule.blog_post',
  blog_home = 'dashboard.automation_rule.blog_page',
  blog_static = 'dashboard.automation_rule.blog_page',
  blog_page = 'dashboard.automation_rule.blog_page',
  blog_post_layout = 'dashboard.automation_rule.blog_post_template',
}

export enum AutomationRuleContentTypesEnum {
  full_access = 'dashboard.automation_rule.full_access',
  dripping_content = 'dashboard.automation_rule.drip-content',
  partial_access = 'dashboard.course.access_type.partial_access',
}
